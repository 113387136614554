import * as React from 'react';
import { useContext } from 'react';
import './ErrorPage.scss';
import Button from 'elements/Buttons/Button/Button';
import Paragraph from 'storybook-components/typography/Paragraph/Paragraph';
import { Space } from 'antd';
import Icon from '@ant-design/icons';
import { ICONS } from 'components/icons';
import { LanguageContext } from '../../contexts/LanguageContext';
import { translations } from '../../elements/Translation/translations';
import { OverlayContext } from '../../services/OverlayContext/OverlayContext';


export function ErrorPage(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const { goBack } = useContext(OverlayContext);


  return (
    <div className="ErrorPage">
      <Paragraph level={1}>
        {tl(translations.pages.errorPage.errorOccured)}
      </Paragraph>
      <Space size={24}>
        <Button onClick={goBack} icon={<Icon component={ICONS.arrowLeft} />}>
          {tl(translations.pages.errorPage.back)}
        </Button>

        <Button onClick={() => window.location.reload()} icon={<Icon component={ICONS.refresh} />}>
          {tl(translations.pages.errorPage.reload)}
        </Button>
      </Space>
    </div>
  );
}
