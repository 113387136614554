import React, {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { Modal } from 'antd';
import {
  matchPath,
  Prompt, useLocation, useParams,
} from 'react-router';
import _ from 'lodash';
import { LanguageContext } from 'contexts/LanguageContext';
import './OwnersMeetingInvitationEditing.scss';
import FormSidebar from 'elements/FormElements/FormSidebar/FormSidebar';
import FormSection from 'elements/FormElements/FormSection/FormSection';
import { generateMenuFromSections } from 'lib/Utils';
import { OwnersMeetingInvitationContext } from 'contexts/OwnersMeetingInvitationContext';
import { useCurrentOverlayInfo } from 'components/OverlayRoute/services/useCurrentOverlayInfo';
import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import { WKA_EDIT_PATH } from 'pages/Wka/routes';
import useTypeSection from './sections/useTypeSection';
import useAppointmentSection from './sections/useAppointmentSection';
import { ownersMeetingInvitationTranslations } from './translations/OwnersMeetingInvitationTranslations';
import useOwnersMeetingInvitationDocumentsSection
  from './sections/OwnersMeetingInvitationDocumentSection/OwnersMeetingInvitaionDocumentsSection';
import Button from '../../../elements/Buttons/Button/Button';
import {
  OwnersMeetingInvitationDtoStatusEnum,
} from '../../../api/accounting';
import { useAgendaItemsSection } from './sections/AgendaSection/useAgendaItemsSection';
import OwnerMeetingInvitationEditingHeader from './components/OwnerMeetingInvitationEditingHeader/OwnerMeetingInvitationEditingHeader';
import { OWNERS_MEETING_EDIT_PATH } from '../routes';

interface LocationStateType {
  openSection?: number,
  messagesCreated?: boolean,
}

function OwnersMeetingInvitationEditing(): JSX.Element {
  const { etvId } = useParams<{ etvId: string }>();
  const location = useLocation<LocationStateType>();
  const viewMode = location.pathname.split('/')[3] === 'view';

  const { tl } = useContext(LanguageContext);
  const ownersMeetingContext: any = useContext(OwnersMeetingInvitationContext);

  const [openSectionIndex, setOpenSectionIndex] = useState(0);
  const [dirtModalVisible, setDirtModalVisible] = useState(false);
  const [dirtModalSection, setDirtModalSection] = useState(-1);
  const openNextSectionOnSuccessRef = useRef(false);


  const {
    setSelectedOwnersMeetingId, ownersMeeting, onSaveOwnersMeeting, onClearOwnersMeeting, updateOwnersMeeting, onSendOutOwnersMeeting, setOwnersMeeting,
    isDirty, isDirtyRef, setDirty, unitContracts, onLoadOwnersMeetingList, markETVSent,
  } = ownersMeetingContext;

  const sectionIsDisabled = ownersMeeting.data.id === undefined;
  const { isOverlayOnTop } = useCurrentOverlayInfo();
  useEffect(() => {
    if (openSectionIndex >= 0) {
      const sectionId = sections[openSectionIndex] ? sections[openSectionIndex].sectionId : null;
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [openSectionIndex]);

  useEffect(() => {
    if (!isOverlayOnTop) {
      onLoadOwnersMeetingList(true);
    }
  }, [isOverlayOnTop]);

  useEffect(() => {
    if (location.state && location.state.openSection && _.isEmpty(ownersMeeting.validationErrors)) {
      openNextSectionOnSuccessRef.current = true;
      handleOpenSectionChange(location.state.openSection);
    }

    if (location.state?.messagesCreated) {
      markETVSent();
    }
  }, [location.state]);

  useEffect(() => {
    if (etvId) setSelectedOwnersMeetingId(etvId);
    else {
      onClearOwnersMeeting();
    }
  }, [etvId, location]);

  useEffect(() => {
    if (ownersMeeting.saved) {
      setDirty(false);

      if (_.isEmpty(ownersMeeting.validationErrors)) {
        if (viewMode) {
          setOpenSectionIndex(-1);
        } else if (openNextSectionOnSuccessRef.current) {
          if (dirtModalSection !== -1) {
            setOpenSectionIndex(dirtModalSection);
            setDirtModalSection(-1);
          } else {
            setOpenSectionIndex(openSectionIndex + 1);
          }
        } else {
          setOpenSectionIndex(-1);
        }
      }
    }
    setDirtModalVisible(false);
  }, [ownersMeeting.saved]);

  const openNextSection = () => {
    setOpenSectionIndex(openSectionIndex + 1);
  };


  const sections: any[] = [
    useTypeSection({ index: 0, setDirty }),
    useAppointmentSection({ index: 1, disabled: sectionIsDisabled }),
    useAgendaItemsSection({
      index: 2,
      openNextSection,
      disabled: sectionIsDisabled,
      isDisabled: ownersMeeting.data.status !== OwnersMeetingInvitationDtoStatusEnum.DRAFT,
      showAddButton: openSectionIndex === 2,
    }),
    useOwnersMeetingInvitationDocumentsSection({
      index: 3,
      setDirty,
      ownersMeeting: ownersMeeting.data,
      unitContracts: unitContracts.data,
      setOwnersMeeting,
      houseMoneySettlementEconomicYears: ownersMeetingContext.houseMoneySettlementEconomicYears,
      economicPlans: ownersMeetingContext.economicPlans,
      wkas: ownersMeetingContext.wkas,
      disabled: sectionIsDisabled,
    }),
  ];

  const save = (openNextSectionOnSuccess: boolean): void => {
    openNextSectionOnSuccessRef.current = openNextSectionOnSuccess;
    setDirtModalSection(-1);
    const openSection = sections.find(section => section.openingNumber === openSectionIndex + 1);
    if (openSection && typeof openSection.onSubmit !== 'undefined') {
      // if there is custom onSave for the section
      openSection.onSubmit();
      return;
    }
    onSaveOwnersMeeting(openSectionIndex + 1, etvId);
  };

  const sendOut = (): void => {
    onSaveOwnersMeeting(openSectionIndex + 1, etvId).then(() => {
      onSendOutOwnersMeeting();
    });
  };


  const handleDirtModalDiscard = (): void => {
    setDirtModalVisible(false);
    setDirty(false);
    if (etvId) {
      setSelectedOwnersMeetingId(etvId);
    } else {
      onClearOwnersMeeting();
    }

    openSectionIndex === dirtModalSection ? setOpenSectionIndex(-1) : setOpenSectionIndex(dirtModalSection);
  };

  const handleDirtModalSave = (): void => {
    openNextSectionOnSuccessRef.current = true;
    onSaveOwnersMeeting(openSectionIndex + 1, etvId);
  };

  const handleOpenSectionChange = (nextSectionIndex: number, isDisabled: boolean = false): void => {
    if (isDisabled) return;
    if (openSectionIndex === nextSectionIndex) nextSectionIndex = -1;
    if (isDirty) {
      setDirtModalVisible(true);
      setDirtModalSection(nextSectionIndex);
    } else {
      setOpenSectionIndex(nextSectionIndex);
    }
  };

  const menuElements: any[] = generateMenuFromSections({
    sections,
    openSectionIndex,
  });

  const sent = useMemo(() => ownersMeeting.data && (ownersMeeting.data.status === OwnersMeetingInvitationDtoStatusEnum.SENT || ownersMeeting.data.status === OwnersMeetingInvitationDtoStatusEnum.SENDING), [ownersMeeting.data]);

  return (
    <Page className="OwnersMeetingInvitationEditing">
      <Prompt
        when={isDirtyRef.current}
        message={loc => ((!isDirtyRef.current || matchPath(loc.pathname, { path: `*${OWNERS_MEETING_EDIT_PATH}` }))
          ? true : tl(ownersMeetingInvitationTranslations.elements.prompt))}
      />
      <OwnerMeetingInvitationEditingHeader save={() => save(true)} editMode={!viewMode && !sent} onSend={sendOut} />
      <PageContent className={`page-content ${viewMode ? 'view-mode' : 'edit-mode'}`}>
        <div id="scrollElement">
          {sections.map((section: any) => {
            const prevSectionIndex = section.openingNumber - 1;
            const onChange = (value: any) => {
              updateOwnersMeeting(value);
            };

            return (
              <FormSection
                key={section.openingNumber}
                open={(openSectionIndex === prevSectionIndex)}
                addDirt={() => setDirty(true)}
                onClickHeader={() => handleOpenSectionChange(prevSectionIndex, section.isDisabled)}
                onSubmit={() => save(true)}
                onChange={onChange}
                hideSaveButton={viewMode}
                loading={ownersMeeting.loading}
                validationErrors={ownersMeeting.validationErrors}
                sectionDisabled={section.disabled}
                {...section}
              />
            );
          })}
        </div>
        <FormSidebar
          elements={menuElements}
          openSection={openSectionIndex}
          onOpenSection={handleOpenSectionChange}
          scrollTarget={document.getElementsByClassName('form-content')[0]}
        />
      </PageContent>
      <Modal
        visible={dirtModalVisible}
        title={tl(ownersMeetingInvitationTranslations.elements.dirtModal.title)}
        onCancel={() => setDirtModalVisible(false)}
        footer={[
          <Button
            key="cancel"
            type="text"
            onClick={() => {
              setDirtModalVisible(false);
              setDirty(false);
            }}
          >
            {tl(ownersMeetingInvitationTranslations.elements.dirtModal.cancel)}
          </Button>,
          <Button key="discard" type="ghost" onClick={handleDirtModalDiscard}>
            {tl(ownersMeetingInvitationTranslations.elements.dirtModal.discard)}
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={handleDirtModalSave}
            loading={ownersMeeting.loading}
          >
            {tl(ownersMeetingInvitationTranslations.elements.dirtModal.save)}
          </Button>,
        ]}
      >
        <p>{tl(ownersMeetingInvitationTranslations.elements.dirtModal.description)}</p>
      </Modal>
    </Page>
  );
}

export default function OwnersMeetingInvitationEditingWithProvider() {
  return (
    <OwnersMeetingInvitationEditing />
  );
}
