import { RouteDefinition } from '../../components/RenderRoutes';
import ResolutionRecordPropertyList from './ResolutionRecordPropertyList/ResolutionRecordPropertyList';
import ResolutionRecordOverview from './ResolutionRecordOverview/ResolutionRecordOverview';
import ResolutionRecordEditing from './ResolutionRecordEditing/ResolutionRecordEditing';
import ResolutionRecordDocument from './ResolutionRecordDocument/ResolutionRecordDocument';

export const getResolutionRecordRoute = ({
  propertyHrId,
}: {
  propertyHrId: string,
}) => (
  `/resolution-records/${propertyHrId}/full-document`
);

export const ResolutionRecordRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: '/resolution-records',
      component: ResolutionRecordPropertyList,
      subRoutes: [
        {
          path: '/resolution-records/:propertyHrId',
          component: ResolutionRecordOverview,
          type: 'overlay',
          subRoutes: [
            {
              path: '/resolution-records/:propertyHrId/create',
              component: ResolutionRecordEditing,
              type: 'overlay',
            },
            {
              path: '/resolution-records/:propertyHrId/edit/:serialNr',
              component: ResolutionRecordEditing,
              type: 'overlay',
            },
            {
              path: '/resolution-records/:propertyHrId/full-document',
              exact: true,
              component: ResolutionRecordDocument,
              type: 'focus',
            },
          ],
        },
      ],
    },
    {
      path: '*/:propertyHrId/resolution-records',
      component: ResolutionRecordPropertyList,
      type: 'overlay',
      width: '90%',
      subRoutes: [
        {
          path: '*/:propertyHrId/resolution-records/create',
          component: ResolutionRecordEditing,
          type: 'overlay',
        },
        {
          path: '*/:propertyHrId/resolution-records/edit/:serialNr',
          component: ResolutionRecordEditing,
          type: 'overlay',
        },
        {
          path: '*/:propertyHrId/resolution-records/document/:serialNr',
          component: ResolutionRecordDocument,
          type: 'focus',
        },
        {
          path: '*/:propertyHrId/resolution-records/full-document',
          exact: true,
          component: ResolutionRecordDocument,
          type: 'focus',
        },
      ],
    },
  ],
};
