export const translations = {
  title: {
    en: 'Meetings protocol',
    de: 'ETV Übersicht',
  },
  headers: {
    etvId: {
      en: 'ID',
      de: 'ID',
    },
    status: {
      en: 'Status',
      de: 'Status',
    },
    internalNr: {
      en: 'Property-Nr.',
      de: 'Objekt-Nr.',
    },
    propertyHrId: {
      en: 'Property ID',
      de: 'Objekt ID',
    },
    propertyName: {
      en: 'Property name',
      de: 'Objektbezeichnung',
    },
    property: {
      en: 'Property',
      de: 'Objekt',
    },
  },
  actions: {
    delete: {
      en: 'Delete',
      de: 'Löschen',
    },
    create: {
      en: 'Create',
      de: 'Erstellen',
    },
  },
  deleteModal: {
    title: {
      en: 'Are you sure you want to delete this?',
      de: 'Möchten Sie dies wirklich löschen?',
    },
    content: {
      en: 'You cannot recover your data.',
      de: 'Sie können Ihre Daten nicht wiederherstellen.',
    },
    okText: {
      en: 'Delete',
      de: 'Löschen',
    },
    cancelText: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
  },
  revertToDraftModal: {
    title: {
      en: 'Revert to DRAFT?',
      de: 'Auf Entwurf zurücksetzen?',
    },
    content: {
      en: 'If you revert this protocol to draft, it will delete the related Resolution Record. Please check the numbering of the resolution record.',
      de: 'Wenn Sie dieses Protokoll auf Entwurf zurücksetzen, wird die zugehörige Beschlusssammlung gelöscht. Bitte prüfen Sie die Nummerierung der Beschlusssammlung.',
    },
    okText: {
      en: 'Revert to draft',
      de: 'Auf Entwurf zurücksetzen',
    },
    cancelText: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
  },
  list: {
    status: {
      en: 'Status',
      de: 'Status',
    },
    propertyName: {
      en: 'Property name',
      de: 'Objektbezeichnung',
    },
    property: {
      en: 'Property',
      de: 'Objekt',
    },
    date: {
      en: 'Date',
      de: 'Datum',
    },
    type: {
      en: 'Type',
      de: 'Art',
    },
    place: {
      en: 'Location',
      de: 'Ort',
    },
    meetingLeader: {
      en: 'Meeting leader',
      de: 'Versammlungsleiter',
    },
    agenda: {
      en: 'Agenda items',
      de: 'Anzahl Tagesordnungspunkte',
    },
    location: {
      en: 'Location',
      de: 'Ort',
    },
    regular: {
      en: 'Ordinary',
      de: 'Ordentliche ETV',
    },
    extraordinary: {
      en: 'Extraordinary',
      de: 'Außerordentliche ETV ',
    },
    circular_resolution: {
      en: 'Circular Resolution',
      de: 'Umlaufbeschluss ',
    },
  },
  editPage: {
    checkMessages: {
      en: 'View messages',
      de: 'Nachrichten ansehen',
    },
    createTitle: {
      en: 'Create new ETV Protocol',
      de: 'Neues ETV Protokoll erstellen',
    },
    editTitle: {
      en: 'Edit ETV Protocol',
      de: 'ETV Protokoll bearbeiten',
    },
    close: {
      en: 'Close',
      de: 'Schließen',
    },
    saveAll: {
      en: 'Save all',
      de: 'Als Entwurf Speichern',
    },
    finalize: {
      en: 'Mark Finalized',
      de: 'Als finalisiert markieren',
    },
    revertToDraft: {
      en: 'Revert to draft',
      de: 'Auf Entwurf zurücksetzen',
    },
    downloadSignatureList: {
      en: 'Signature List Download',
      de: 'Unterschriftenliste herunterladen',
    },
    sidebar: {
      title: {
        en: 'Protocol',
        de: 'Beschlusssammlung',
      },
    },
    attendanceSummary: {
      from: {
        en: 'of',
        de: 'von',
      },
      owners: {
        en: 'Owners',
        de: 'Eigentümer',
      },
      units: {
        en: 'Units',
        de: 'Einheiten',
      },
      status: {
        OK: {
          en: 'Quorum',
          de: 'Beschlussfähig',
        },
        PROBLEM: {
          en: 'Declined',
          de: 'Abgelehnt',
        },
      },
    },
    sections: {
      generalData: {
        title: {
          en: 'General data',
          de: 'Allgemein',
        },
        property: {
          en: 'Property',
          de: 'Objekt',
        },
        date: {
          en: 'Date',
          de: 'Datum',
        },
        docTitle: {
          en: 'Document name',
          de: 'Titel',
        },
        propertyName: {
          en: 'Property name',
          de: 'Objektbezeichnung',
        },
        typeSection: {
          title: {
            en: 'ETV type',
            de: 'ETV Art',
          },
          typeOptions: {
            regular: {
              en: 'Ordinary ETV',
              de: 'Ordentliche ETV',
            },
            extraordinary: {
              en: 'Extraordinary ETV',
              de: 'Außerordentliche ETV ',
            },
          },
        },
        location: {
          en: 'Meeting location',
          de: 'Versammlungsort',
        },
        room: {
          en: 'Meeting room',
          de: 'Versammlungsraum',
        },
        protocolTitle: {
          en: 'Title',
          de: 'Titel',
        },
        watermark: {
          en: 'Watermark',
          de: 'Briefpapier',
        },
      },
      attendeeList: {
        title: {
          en: 'Attendee list',
          de: 'Anwesenheit',
        },
        emptyMessage: {
          en: 'No signing person has been added.\n You can add a signing person with the button below.',
          de: 'Es wurde keine unterzeichnende Person hinzugefügt.\n Sie können eine unterzeichnende Person mit der untenstehende Schaltfläche hinzufügen.',
        },
        columns: {
          number: {
            en: 'Number',
            de: 'Nr.',
          },
          contact: {
            en: 'Contact',
            de: 'Kontakt',
          },
          owners: {
            en: 'Owners',
            de: 'Eigentümer',
          },
          unit: {
            en: 'Unit',
            de: 'Einheit',
          },
          name: {
            en: 'Name',
            de: 'Name',
          },
          units: {
            en: 'Units',
            de: 'Einheiten',
          },
          mea: {
            en: 'MEA',
            de: 'MEA',
          },
          mailingContactName: {
            en: 'Mailing Contact Name',
            de: 'Mailing Kontakt Name',
          },
          unitNrSharingDeclaration: {
            en: 'Unit Nr sharing declaration',
            de: 'Einheiten-Nr. lt. Teilungserklärung',
          },
          powerOfAttorney: {
            en: 'Power of attorney',
            de: 'Vollmacht',
          },
          presenceStatus: {
            en: 'Presence status',
            de: 'Anwesenheits-Status',
          },
          signatures: {
            en: 'Signatures',
            de: 'Unterschriften',
          },
        },
        powerOfAttorneyOptions: {
          notProvided: {
            en: 'Not provided',
            de: 'Nicht vorgesehen',
          },
          propertyManager: {
            en: 'Property manager',
            de: 'Verwalter',
          },
          propertyManagerInstructed: {
            en: 'Property manager instructed',
            de: 'Verwaltervollmacht',
          },
          otherPerson: {
            en: 'Other person',
            de: 'Drittvollmacht',
          },
        },
      },
      agendaSection: {
        title: {
          en: 'Protocol',
          de: 'Protokoll',
        },
        tooltip: {
          MEA: {
            en: 'Vote based on shares',
            de: 'Stimmrecht gemäß Teilungserklärung',
          },
          heads: {
            en: '1 person 1 vote',
            de: '1 Stimme / Person',
          },
        },
        createRecord: {
          title: {
            en: 'Add to resolution record',
            de: 'An die Beschlusssammlung übergeben',
          },
          yes: {
            en: 'Yes',
            de: 'Ja',
          },
          no: {
            en: 'No',
            de: 'Nein',
          },
        },
        resolutionRecord: {
          en: 'Resolution record text',
          de: 'Beschlussfassungstext',
        },
        comment: {
          en: 'Comments',
          de: 'Kommetierung',
        },
        vote: {
          en: 'Vote',
          de: 'Abstimmen',
        },
        passed: {
          en: 'Accepted',
          de: 'Angenommen',
        },
        failed: {
          en: 'Declined',
          de: 'Abgelehnt',
        },
        saveSuccess: {
          message: {
            en: 'ETV saved successfully.',
            de: 'ETV erfolgreich gespeichert.',
          },
        },
        saveError: {
          message: {
            en: 'ETV could not be saved.',
            de: 'ETV konnte nicht gespeichert werden.',
          },
          description: {
            en: 'Cannot save ETV.',
            de: 'ETV kann nicht gespeichert werden.',
          },
        },
      },
      protocolSetup: {
        title: {
          en: 'Protocol statement',
          de: 'Protokollarische Feststellung',
        },
        protocolText: {
          en: 'Protocol text',
          de: 'Protokollarischer Text',
        },
        radioGroup: {
          label: {
            en: 'Text',
            de: 'Text',
          },
          options: {
            standard: {
              en: 'Standard',
              de: 'Standardmäßig',
            },
            alternative: {
              en: 'Alternative',
              de: 'Alternative',
            },
          },
        },
        meetingDuration: {
          title: {
            en: 'Meeting duration',
            de: 'Versammlungsdauer',
          },
          fields: {
            start: {
              en: 'Start',
              de: 'Beginn',
            },
            end: {
              en: 'End',
              de: 'Ende',
            },
          },
        },
        meetingManagement: {
          title: {
            en: 'Meeting management',
            de: 'Versammlungsleitung',
          },
          fields: {
            leader: {
              en: 'Meeting leader',
              de: 'Versammlungsleiter',
            },
            role: {
              en: 'Role',
              de: 'Rolle',
            },
          },
        },
        protocolLeader: {
          title: {
            en: 'Protocol leader',
            de: 'Protokollführer',
          },
          fields: {
            protocolLeader: {
              en: 'Minute taker',
              de: 'Protokollführer',
            },
            role: {
              en: 'Role',
              de: 'Rolle',
            },
          },
        },
        agenda: {
          title: {
            en: 'Agenda',
            de: 'Tagesordnung',
          },
        },
        votingRights: {
          title: {
            en: 'Voting rights',
            de: 'Stimmrecht',
          },
          fields: {
            byUnits: {
              en: 'By',
              de: 'Nach',
            },
          },
        },
        quorum: {
          title: {
            en: 'Quorum',
            de: 'Beschlussfähigkeit',
          },
          fields: {
            coownership: {
              en: 'Present MEA',
              de: 'Miteigentumsanteile',
            },
            voting: {
              en: 'Votes',
              de: 'Stimmen',
            },
          },
        },
        votingScheme: {
          title: {
            en: 'Voting scheme',
            de: 'Abstimmungsregelung',
          },
          fields: {
            votingScheme: {
              en: 'Voting scheme',
              de: 'Abstimmungsregelung',
            },
          },
          votingSchemeOptions: {
            openVoting: {
              en: 'open voting',
              de: 'offen',
            },
            secretVoting: {
              en: 'secret voting',
              de: 'geheim',
            },
          },
        },
      },
      confirmProtocol: {
        title: {
          en: 'Confirm protocol',
          de: 'Protokoll bestätigen',
        },
        submitForSignature: {
          title: {
            en: 'Submit for signature',
            de: 'Zur Unterschrift abgeben an',
          },
          confirmationText: {
            en: 'This will send out the protocol for all signing people',
            de: 'Das Protokoll wird an alle Unterzeichner gesendet.',
          },
          addSigningPerson: {
            en: 'Add signing person',
            de: 'Person hinzufügen',
          },
          meetingLeader: {
            en: 'Meeting leader',
            de: 'Versammlungsleiter',
          },
          signingRoles: {
            ADVISORY_BOARD_MEMBER: {
              en: 'Advisory board member',
              de: 'Beirat',
            },
            ADVISORY_BOARD_PRESIDENT: {
              en: 'Advisory board president',
              de: 'Beiratsvorsitz',
            },
            OWNER: {
              en: 'Owner',
              de: 'Eigentümer',
            },
          },
          signingDispatchTypes: {
            PORTAL: {
              en: 'Portal',
              de: 'Portal',
            },
            EPOST: {
              en: 'E-Post',
              de: 'E-Post',
            },
            NO_DISPATCH: {
              en: 'No dispatch',
              de: 'Kein Versand',
            },
            MANUAL: {
              en: 'Manual',
              de: 'Manuell',
            },
          },
          columns: {
            role: {
              en: 'Role',
              de: 'Rolle',
            },
            dispatchType: {
              en: 'Dispatch type',
              de: 'Versandweg',
            },
          },
        },
      },
      sendProtocol: {
        title: {
          en: 'Send out',
          de: 'Versenden',
        },
        protocolSignedConfirmation: {
          title: {
            en: 'Has the protocol been signed?',
            de: 'Protokoll unterschrieben?',
          },
          protocolSigned: {
            yes: {
              en: 'Yes',
              de: 'Ja',
            },
            no: {
              en: 'No',
              de: 'Nein',
            },
          },
          originalScan: {
            en: 'Upload signed document',
            de: 'Signiertes Dokument hochladen',
          },
        },
        protocolSending: {
          title: {
            en: 'Send',
            de: 'Versenden',
          },
          send: {
            en: 'Send out',
            de: 'Versenden',
          },
          disabled: {
            en: 'Protocol must be signed first and can only be sent out once',
            de: 'Das Protokoll muss erst unterschrieben werden und kann nur einmal versendet werden',
          },
          confirmationText: {
            en: 'This will send out the protocol for all owners',
            de: 'Das wird den Protokoll an allen Eigentümern verschicken.',
          },
          dispatchType: {
            EPOST: {
              en: 'E-Post (physical)',
              de: 'E-Post (physisch)',
            },
            PORTAL: {
              en: 'Portal',
              de: 'Portal',
            },
          },
          dispatchViaPreferenceTitle: {
            en: 'Distribute via',
            de: 'Zustellung via',
          },
          dispatchViaPreferenceOptions: {
            preference: {
              en: 'By preference',
              de: 'Kontaktpräferenz',
            },
            selection: {
              en: 'Based on selection',
              de: 'Anhand der Auswahl',
            },
          },
          columns: {
            dispatchType: {
              en: 'Dispatch type',
              de: 'Versandweg',
            },
          },
        },
        additionalDocuments: {
          selectDocument: {
            en: 'Select attachment',
            de: 'Anhang auswählen',
          },
          addDocument: {
            en: 'Add attachment',
            de: 'Anhang hinzufügen',
          },
        },
        sendModal: {
          title: {
            en: 'Are you sure you want to send it?',
            de: 'Sind Sie sicher, dass sie das Protokoll versenden möchten?',
          },
          ok: {
            en: 'Yes',
            de: 'Ja',
          },
          cancel: {
            en: 'Cancel',
            de: 'Abbrechen',
          },
        },
      },
      uploadPowerOfAttorneySection: {
        title: {
          en: 'Upload power of attorney',
          de: 'Vollmacht Hochladen',
        },
        name: {
          en: 'Name',
          de: 'Name',
        },
        powerOfAttorney: {
          en: 'Power of attorney',
          de: 'Vollmacht',
        },
        preview: {
          en: 'Preview',
          de: 'Vorschau',
        },
        delete: {
          en: 'Delete',
          de: 'Löschen',
        },
      },
    },
  },
  states: {
    OPEN: {
      en: 'Open',
      de: 'Offen',
    },
    SENT_FOR_SIGNATURE: {
      en: 'Sent for signing',
      de: 'Zur Unterschrift abgegeben',
    },
    SENT: {
      en: 'Sent',
      de: 'Versendet',
    },
    DONE: {
      en: 'Done',
      de: 'Finalisiert',
    },
    PROBLEM: {
      en: 'Problem',
      de: 'Konnte nicht gesendet werden',
    },
    DELETED: {
      en: 'Deleted',
      de: 'Gelöscht',
    },
  },
  distributionOptions: {
    mea: {
      en: 'MEA',
      de: 'Miteigentumsanteil',
    },
    units: {
      en: 'Units',
      de: 'Einheiten',
    },
    headcount: {
      en: 'People head count',
      de: 'Kopfprinzip',
    },
  },
  roleOptions: {
    managingDirector: {
      en: 'Managing director',
      de: 'Geschäftsführer',
    },
    employee: {
      en: 'Employee',
      de: 'Mitarbeiter',
    },
    owner: {
      en: 'Owner',
      de: 'Eigentümer',
    },
    propertyManager: {
      en: 'Property manager',
      de: 'Objektleiter',
    },
  },
  powerOfAttorneyOptions: {
    PROPERTY_MANAGER: {
      en: 'Property manager',
      de: 'Verwalter',
    },
    PROPERTY_MANAGER_INSTRUCTED: {
      en: 'Property manager instructed',
      de: 'Verwaltervollmacht',
    },
    OTHER_PERSON: {
      en: 'Other person',
      de: 'Drittvollmacht',
    },
  },
  buttons: {
    create: {
      en: 'Create ETV protocol',
      de: 'Hinzufügen',
    },
  },
  notifications: {
    sendSuccess: {
      message: {
        en: 'ETV Protocol was sent out successfully.',
        de: 'ETV Protokoll erfolgreich versendet.',
      },
    },
    errorCreatingDocument: {
      en: 'Failed to create document.',
      de: 'Dokument konnte nicht erstellt werden.',
    },
    sendOutError: {
      message: {
        en: 'Could not send out ETV Protocol.',
        de: 'ETV Protokoll konnte nicht gesendet werden.',
      },
      noSigningPerson: {
        en: 'Please add at least one signing person.',
        de: 'Bitte fügen Sie mindestens eine unterzeichnende Person hinzu.',
      },
      noDispatchType: {
        en: 'Dispatch type is not selected.',
        de: 'Versandart ist nicht ausgewählt.',
      },
      invalidEpostConfig: {
        en: 'Failed to send document with ePost. EPost configuration is invalid!',
        de: 'Der Brief konnte nicht über EPOST gesendet werden. EPOST Konfiguration ist ungültig',
      },
      invalidCasaviConfig: {
        en: 'Failed to upload document to Casavi. Casavi configuration is invalid!',
        de: 'Der Brief konnte nicht über Portal hochladen werden. Portal Konfiguration ist ungültig',
      },
    },
    loadOwnersMeetingListError: {
      message: {
        en: 'ETV protocol couldn´t be loaded.',
        de: 'ETV Protokoll konnten nicht geladen werden.',
      },
    },
    noChangeMsg: {
      message: {
        en: 'Nothing changed',
        de: 'Nichts hat sich verändert',
      },
    },
    loadOwnersMeetingError: {
      en: 'Failed loading ETV protocol',
      de: 'ETV Protokoll konnte nicht geladen werden',
    },
    loadOwnersMeetingProtocolRecipientError: {
      en: 'Failed loading ETV protocol recipients',
      de: 'Empfänger des ETV Protokolls konnten nicht geladen werden',
    },
    saveSuccess: {
      en: 'Owners meeting protocol saved successfully',
      de: 'ETV Protokoll wurde erfolgreich gespeichert',
    },
    saveError: {
      en: 'Owners meeting protocol could not be saved',
      de: 'ETV Protokoll konnte nicht gespeichert werden',
    },
    validationError: {
      en: 'There are validation errors',
      de: 'Es sind Validierungsfehler aufgetreten',
    },
    deleteSuccess: {
      en: 'Owners meeting protocol deleted successfully',
      de: 'ETV Protokoll wurde erfolgreich gelöscht',
    },
    deleteError: {
      en: 'Cannot delete owners meeting protocol',
      de: 'ETV Protokoll konnte nicht gelöscht werden',
    },
    loadUnitContractError: {
      en: 'Failed to load unit contract',
      de: 'Verträge der Einheit konnten nicht geladen werden',
    },
    finalizeOwnersMeetingProtocolError: {
      en: 'Failed to mark ETV protocol as finalized',
      de: 'ETV Protokoll konnte nicht als finalisiert markiert werden',
    },
    revertOwnersMeetingProtocolErrorToDraft: {
      en: 'Failed to revert ETV protocol as draft',
      de: 'ETV Protokoll konnte nicht als Entwurf zurückgesetzt werden',
    },
    failedToDownloadPdf: {
      message: {
        en: 'Failed to download document',
        de: 'Fehler beim Herunterladen des Dokuments',
      },
      invalidDataMessage: {
        en: 'Failed to download document because of validation errors',
        de: 'Fehler beim Herunterladen des Dokuments aufgrund von Validierungsfehlern',
      },
    },
  },
};
