import './ResolutionRecordOverview.scss';

import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Search from 'antd/es/input/Search';
import { LanguageContext } from 'contexts/LanguageContext';
import { PropertyListContext } from 'contexts/PropertyListContext';
import { ResolutionRecordContext } from 'contexts/ResolutionRecordContext';
import Button from 'elements/Buttons/Button/Button';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import { translations } from 'elements/Translation/translations';
import { formatDate } from 'lib/Utils';
import {
  useHistory,
  useParams,
} from 'react-router';

import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import PageHeader from 'storybook-components/layout/PageHeader/PageHeader';
import useWatermarkOptions from 'services/useWatermarkOptions';
import { Modal } from 'antd';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import {
  PropertyLegacyDtoPropertyStateEnum,
} from '../../../api/accounting/models';
import {
  useGlobalSearch,
} from '../../../components/Header/components/SearchBar/services/useGlobalSearch';
import PropertyInfo from '../../../elements/PropertyInfo/PropertyInfo';
import SmartTable from '../../../elements/SmartTable/SmartTable';
import {
  useResolutionRecordOverviewColumns,
} from './UseResolutionRecordOverviewColumns';
import { getResolutionRecordRoute } from '../routes';

export default function ResolutionRecordOverview(): JSX.Element {
  const { propertyHrId } = useParams<{ propertyHrId: string }>();
  const languageContext = useContext(LanguageContext);
  const { tl } = languageContext;
  const resolutionRecordContext: any = useContext(ResolutionRecordContext);
  const history = useHistory();
  const { selectedProperty, setSelectedPropertyHrId }: any = useContext(PropertyListContext);
  const propertyListContext: any = useContext(PropertyListContext);
  const {
    propertyId, setPropertyId, resolutionRecordList, onLoadResolutionRecord, onClearResolutionRecordList, sortField, sortOrder, watermarkId, setWatermarkId,
  }: any = useContext(ResolutionRecordContext);
  const [filter, setFilter] = useState('');
  const { watermarkOptions, defaultWatermarkId } = useWatermarkOptions();

  useGlobalSearch({ key: 'resolutionRecords' });

  useEffect(() => {
    if (defaultWatermarkId) {
      onChangeWatermark(defaultWatermarkId);
    }
  }, [defaultWatermarkId]);

  const onChangeWatermark = (val: number) => {
    setWatermarkId(val);
  };

  useEffect(() => () => {
    onClearResolutionRecordList();
  }, []);

  useEffect(() => {
    if (propertyId) {
      onLoadResolutionRecord(true);
    }
  }, [resolutionRecordContext.resolutionRecord.saved, sortField, sortOrder, propertyId]);

  useEffect(() => {
    if (propertyHrId) {
      setSelectedPropertyHrId(propertyHrId);
    }
  }, [propertyHrId]);

  useEffect(() => {
    onLoadResolutionRecord(true, filter);
  }, [filter]);

  useEffect(() => {
    if (selectedProperty.loaded && selectedProperty.data) {
      setPropertyId(selectedProperty.data.id);
    }
  }, [selectedProperty]);


  function mapLegalEvent(legalEventType: string) {
    switch (legalEventType) {
      case 'COURT_DECISION':
        return tl(translations.pages.resolutionRecordEditing.section4.eventTypes.courtDecision);
      case 'CHALLENGE':
        return tl(translations.pages.resolutionRecordEditing.section4.eventTypes.challenge);
      default:
        return tl(translations.pages.resolutionRecordEditing.section4.eventTypes.else);
    }
  }

  function mapMeetingType(meetingType: string) {
    switch (meetingType) {
      case 'ORDINARY':
        return tl(translations.pages.resolutionRecordEditing.section2.meetingTypes.ordinary);
      case 'EXTRAORDINARY':
        return tl(translations.pages.resolutionRecordEditing.section2.meetingTypes.extraordinary);
      case 'CIRCULAR_RESOLUTION':
        return tl(translations.pages.resolutionRecordEditing.section2.meetingTypes.circular);
      default:
        return '-';
    }
  }

  function mapStatus(status: string) {
    switch (status) {
      case 'ACCEPTED':
        return tl(translations.pages.resolutionRecordEditing.section3.resolutionStatuses.accepted);
      case 'DECLINED':
        return tl(translations.pages.resolutionRecordEditing.section3.resolutionStatuses.declined);
      case 'CHALLENGED':
        return tl(translations.pages.resolutionRecordEditing.section3.resolutionStatuses.challenged);
      case 'LEGAL_VALID':
        return tl(translations.pages.resolutionRecordEditing.section3.resolutionStatuses.legalValid);
      case 'LEGAL_DECIDED':
        return tl(translations.pages.resolutionRecordEditing.section3.resolutionStatuses.legalDecided);
      default:
        return '-';
    }
  }

  const dataSource = useMemo(() => resolutionRecordList.data.map((resolutionRecord: any) => {
    const newResolutionRecord = {
      ...resolutionRecord,
      meetingType: mapMeetingType(resolutionRecord.meetingType),
      location: resolutionRecord.meetingType === 'CIRCULAR_RESOLUTION' ? '-' : resolutionRecord.location,
      resolutionRecordStatus: mapStatus(resolutionRecord.resolutionRecordStatus),
      legalEventsString: '',
      created: '',
    };
    newResolutionRecord.legalEvents.forEach((legalEvent: any, index: number) => {
      newResolutionRecord.created += `\n${formatDate(legalEvent.creationDate)}`;
      newResolutionRecord.legalEventsString += `\n${index + 1}. ${mapLegalEvent(legalEvent.legalEventType)}`;
    });

    return newResolutionRecord;
  }), [resolutionRecordList.data]);

  const resolutionRecordTable = useSmartTable({
    tableName: 'resolutionRecordOverviewTable',
    columns: useResolutionRecordOverviewColumns(),
    dataSource,
    propSort: {
      field: resolutionRecordContext.sortField,
      order: resolutionRecordContext.sortOrder,
      onSortChange: (dataKey: string) => resolutionRecordContext.setSortField(dataKey),
    },
    onRow: (record: any) => ({
      onClick: () => {
        history.push(`/resolution-records/${propertyHrId}/edit/${record.resolutionRecordNumber}`);
      },
    }),
    contentLoading: resolutionRecordList.loading || propertyListContext.loading || propertyListContext.selectedProperty.loading,
    infiniteScrollerProps: {
      hasMoreData: !resolutionRecordList.lastPage,
      loadMoreData: onLoadResolutionRecord,
    },
    rowKey: 'id',
  });

  const showCreateButton = useMemo(() => selectedProperty.data && [PropertyLegacyDtoPropertyStateEnum.READY].includes(selectedProperty.data.propertyState), [selectedProperty]);

  const showWatermarkModal = () => {
    Modal.confirm({
      icon: null,
      title: tl(translations.pages.resolutionRecordOverview.document),
      content: (
        <div>
          <LanguageContext.Provider value={languageContext}>
            <SelectInput
              defaultValue={watermarkId}
              options={watermarkOptions}
              onChange={val => onChangeWatermark(val)}
              allowClear
              label={tl(translations.pages.resolutionRecordOverview.watermark)}
            />
          </LanguageContext.Provider>
        </div>
      ),
      onOk() {
        history.push(getResolutionRecordRoute({ propertyHrId }));
      },
      okText: tl(translations.pages.resolutionRecordOverview.download),
      cancelText: tl(translations.pages.resolutionRecordOverview.cancel),
      okButtonProps: { className: 'Button' },
      cancelButtonProps: { className: 'Button' },
      closable: true,
      maskClosable: true,
    });
  };

  return (
    <Page className="ResolutionRecordOverview">
      <PageHeader
        title={tl(translations.pages.resolutionRecordOverview.title)}
        subtitle={<PropertyInfo property={selectedProperty.data} />}
        rightSideComponent={(
          <div className="buttons">
            <Search
              className="search"
              value={filter}
              placeholder={tl(translations.pages.resolutionRecordOverview.search)}
              onChange={e => setFilter(e.target.value)}
            />
            {dataSource.length > 0 && (
              <Button
                type="default"
                className="button view"
                onClick={showWatermarkModal}
              >
                {tl(translations.pages.resolutionRecordOverview.view)}
              </Button>
            )}
            {showCreateButton
              && (
                <Button
                  type="primary"
                  className="button create"
                  onClick={() => {
                    history.push(`/resolution-records/${propertyHrId}/create`);
                  }}
                >
                  {tl(translations.pages.resolutionRecordOverview.create)}
                </Button>
              )
            }
          </div>
        )}
      />
      <PageContent>
        <SmartTable {...resolutionRecordTable} />
      </PageContent>
    </Page>
  );
}
