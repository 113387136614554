export const translations = {
  property: {
    en: 'Property name | Property-Nr.',
    de: 'Bezeichnung | Objekt-Nr.',
  },
  unitType: {
    en: 'Unit type',
    de: 'Kategorie',
  },
  unitNr: {
    en: 'Unit-Nr.',
    de: 'Einheits-Nr.',
  },
};
