import React, {
  useContext,
  useRef,
  useState,
} from 'react';

import _ from 'lodash';

import { AuthContext } from 'contexts/AuthContext';
import {
  ResolutionRecordControllerApi, ResolutionRecordRenderDto,
} from 'api/accounting';
import { blobToBase64 } from 'services/usePdfUtils';
import { getStandardWatermarkConfig } from 'services/print/utilWatermarkConfig';
import backend, { endpointUrls } from '../backend_api';
import * as config from '../config';
import { translations } from '../elements/Translation/translations';
import DEFAULT_DATA from '../lib/data';
import { showNotification } from '../lib/Notification';
import { LanguageContext } from './LanguageContext';

export const ResolutionRecordContext: any = React.createContext({});

export default function ResolutionRecordProvider({ children }: any) {
  const PAGE_SIZE = 30;
  const [sort, setSort] = useState({
    field: 'resolutionRecordNumber',
    order: 1,
  });
  const sortRef: any = useRef();
  sortRef.current = sort;
  const { tl } = useContext(LanguageContext);

  const defaultResolutionRecord = {
    legalEvents: [
      {},
    ],
  };

  const { apiConfiguration } = useContext(AuthContext);
  const resolutionRecordControllerApi = new ResolutionRecordControllerApi(apiConfiguration('accounting'));
  const [resolutionRecordList, setResolutionRecordList] = useState(DEFAULT_DATA<any>([]));
  const [resolutionRecord, setResolutionRecord] = useState(DEFAULT_DATA<any>(defaultResolutionRecord).load(defaultResolutionRecord, {}, true));
  const [numberOfLegalEvents, setNumberOfLegalEvents] = useState(0);
  const [propertyId, setPropertyId] = useState<number>();
  const [base64Url, setBase64Url] = useState<string | null>(null);
  const [watermarkId, setWatermarkId] = useState<number | null>(null);

  const setSaved = (saved: boolean) => {
    setResolutionRecord(resRecord => ({ ...resRecord, saved }));
  };


  const setSortField = (field: string) => {
    const order = sortRef.current.field === field ? sortRef.current.order * (-1) : 1;
    setSort({
      field,
      order,
    });
  };

  const onLoadResolutionRecord = (resetPage: boolean = false, filter: string = '') => {
    if (!propertyId || resolutionRecordList.loading) return;
    setResolutionRecordList(state => state.startLoading());
    backend.get(`${endpointUrls.RESOLUTION_RECORDS}/${propertyId}`, {
      page: resetPage ? 0 : resolutionRecordList.page,
      size: PAGE_SIZE,
      sort: sortRef.current.field,
      order: sortRef.current.order > 0 ? 'ASC' : 'DESC',
      filter,
    })
      .then((response: any) => {
        setResolutionRecordList(resolutionRecordList.loadPaged(response.content, resetPage, response.last));
      })
      .catch(() => {
        setResolutionRecordList(resolutionRecordList.failed());
        showNotification({
          key: 'loadResolutionRecords',
          message: tl(translations.notifications.resolutionRecordContext.loadError),
          type: 'error',
        });
      });
  };

  const onLoadresolutionRecordDocument = () => {
    const body: ResolutionRecordRenderDto = {
      propertyId,
      watermarkId,
      ...(watermarkId ? { watermarkConfigDto: getStandardWatermarkConfig() } : {}),
    };
    setBase64Url(null);
    return resolutionRecordControllerApi.renderResolutionRecordUsingPOSTRaw({ resolutionRecordRenderDto: body })
      .then((resp: any) => (resp?.raw?.blob()))
      .then((blob: Blob) => (blobToBase64(blob)))
      .then((base64String: string) => setBase64Url(base64String))
      .catch(() => {
        showNotification({
          key: 'loadResolutionRecord',
          message: tl(translations.notifications.pdfViewer.failedToLoad),
          type: 'error',
        });
      });
  };

  const convertToBeModel = () => ({
    propertyId,
    ...resolutionRecord.data,
    legalEvents: !_.isEmpty(resolutionRecord.data.legalEvents) ? resolutionRecord.data.legalEvents.filter((a: any) => !_.isEmpty(a)) : [],
    numberOfParticipant: resolutionRecord.data.presentVotes,
  });

  const convertToFeModel = (response: any) => ({
    ...response,
    legalEvents: response.legalEvents && response.legalEvents[0] ? response.legalEvents : [{}],
  });

  const onLoadResolutionRecordBySerialNr = (serialNumber: number) => {
    if (!propertyId) return;
    setResolutionRecord(resolutionRecord.startLoading());
    backend.get(`${endpointUrls.RESOLUTION_RECORDS}/${propertyId}/${serialNumber}`, {})
      .then((response: any) => {
        const feModel = convertToFeModel(response);
        setResolutionRecord(resolutionRecord.load(feModel, {}, true));
        if (response.legalEvents.length > 0) setNumberOfLegalEvents(response.legalEvents.length);
      })
      .catch(() => {
        setResolutionRecord(resolutionRecord.failed());
        showNotification({
          key: 'loadResolutionRecord',
          message: tl(translations.notifications.resolutionRecordContext.loadError),
          type: 'error',
        });
      });
  };

  const onSaveResolutionRecord = (serialNumber: number | undefined) => {
    setSaved(true);
    setResolutionRecord(resolutionRecord.startLoading());
    let p;
    const beModel = convertToBeModel();
    if (serialNumber) {
      p = backend.put(`${endpointUrls.RESOLUTION_RECORDS}/${serialNumber}`, beModel);
      p.then((response) => {
        const feModel = convertToFeModel(response);
        if (feModel.legalEvents.length > 0) setNumberOfLegalEvents(feModel.legalEvents.length - 1);
        setResolutionRecord(resolutionRecord.load(feModel, {}, true));
      });
    } else {
      p = backend.post(`${endpointUrls.RESOLUTION_RECORDS}`, beModel);
      p.then(() => {
        setResolutionRecord(resolutionRecord.finishLoading());
      });
    }
    p.catch((response) => {
      setSaved(false);
      if (response.title === 'Validation error') {
        setResolutionRecord(resolutionRecord.failed(response));
        showNotification({
          key: 'saveResolutionRecord',
          message: tl(translations.notifications.resolutionRecordContext.saveValidationError.message),
          type: 'error',
        });
      } else {
        setResolutionRecord(resolutionRecord.failed());
        showNotification({
          key: 'saveResolutionRecord',
          message: tl(translations.notifications.resolutionRecordContext.saveError.message),
          type: 'error',
        });
      }
    });
    return p;
  };

  const onClearResolutionRecordList = () => {
    setPropertyId(undefined);
    setResolutionRecordList(DEFAULT_DATA<any>([]));
  };

  const onClearResolutionRecord = () => {
    setResolutionRecord(DEFAULT_DATA<any>(defaultResolutionRecord).load(defaultResolutionRecord, {}, true));
    setNumberOfLegalEvents(0);
  };

  const getResolutionRecordDocumentPath = (serialNumber: any) => `${config.backendUrl}${endpointUrls.RESOLUTION_RECORDS}/${propertyId}/${serialNumber}/document`;


  return (
    <ResolutionRecordContext.Provider value={{
      resolutionRecordList,
      propertyId,
      setPropertyId,
      resolutionRecord,
      setResolutionRecord,
      numberOfLegalEvents,
      setNumberOfLegalEvents,
      setSaved,
      onLoadResolutionRecord,
      onClearResolutionRecordList,
      onClearResolutionRecord,
      onSaveResolutionRecord,
      onLoadResolutionRecordBySerialNr,
      getResolutionRecordDocumentPath,
      setSortField,
      sortField: sortRef.current.field,
      sortOrder: sortRef.current.order,
      onLoadresolutionRecordDocument,
      base64Url,
      watermarkId,
      setWatermarkId,
    }}
    >
      {children}
    </ResolutionRecordContext.Provider>
  );
}
