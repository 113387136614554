export interface FooterProps {
    unitNr?: string;
    propertyName: string;
    documentTitle: string;
  }

// style needs to be inline because it gets send to pdf-proxy on its own

export const Footer: React.FC<FooterProps> = ({ unitNr, propertyName, documentTitle }) => (
  <footer
    style={{
      fontFamily: "'IBM Plex Sans', sans-serif",
      fontSize: '7pt',
      color: '#C8C8C8',
      width: '100%',
      textAlign: 'center',
      position: 'absolute',
      bottom: '17px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <p
      style={{
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
      }}
    >
      {unitNr && (
        <span>
          {unitNr}
          {' '}
          |
        </span>
      )}
      <span
        style={{
          flexShrink: 1,
          maxWidth: '200px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {propertyName}
      </span>
      {' | '}
      <span>{documentTitle}</span>
    </p>
  </footer>
);


Footer.defaultProps = {
  unitNr: undefined,
};
