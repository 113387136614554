import React from 'react';
import './LoadingScreen.scss';
import { LoadingIndicator } from 'elements/LoadingIndicator/LoadingIndicator';


interface LoadingScreenProps{
 title?: string,
 subtitle?: string,
 fitContainer?: boolean,
 centered?: boolean,
}
export function LoadingScreen({
  title, subtitle, fitContainer, centered,
}: LoadingScreenProps) {
  return (
    <div className={`LoadingScreen ${fitContainer ? 'fit-container' : ''} ${centered ? 'centered' : ''}`}>
      <div className="container">
        <div className="loading-wrapper">
          <LoadingIndicator size="large" />
          <div className="loading-message">
            {title && (
              <>
                <span className="loading-message-title">{title}</span>
                <br />
              </>
            )}
            {subtitle && <span className="loading-message-subtitle">{subtitle}</span>}
          </div>
        </div>
      </div>
    </div>
  );
}

LoadingScreen.defaultProps = {
  title: '',
  subtitle: '',
  fitContainer: false,
  centered: false,
};
