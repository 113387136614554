import React, { useContext } from 'react';
import PageHeader from 'storybook-components/layout/PageHeader/PageHeader';
import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import { Table } from 'antd';
import { MainDetails } from 'storybook-components/documents/MainDetails/MainDetails';
import { translations } from './translations';
import { OverlayContext } from '../../../../../../services/OverlayContext/OverlayContext';
import { useAttendeeListColumns } from './useAttendeeListColumns';
import useOwnersMeetingProtocolAttendance from './useOwnersMeetingProtocolAttendance';
import './OwnersMeetingProtocolAttendance.scss';

export const OwnersMeetingProtocolAttendance: React.FC = () => {
  const { tl } = useContext(LanguageContext);
  const { goBack } = useContext(OverlayContext);

  const {
    attendees,
    getMainDetails,
  } = useOwnersMeetingProtocolAttendance();

  const { propertyName, propertyNr } = getMainDetails();

  return (
    <div className="owners-meeting-protocol-attendance">
      <PageHeader
        title={tl(translations.title)}
        titleLevel={1}
        rightSideComponent={(
          <Button
            onClick={() => {
              goBack();
            }}
          >
            {tl(translations.close)}
          </Button>
        )}
      />
      <MainDetails
        details={{ property: propertyNr && propertyName ? `${propertyNr} | ${propertyName}` : '' }}
      />
      <Table
        className="ProtocolAttendeesTable"
        dataSource={attendees}
        rowKey="contractId"
        columns={useAttendeeListColumns()}
        pagination={false}
      />
    </div>
  );
};
