import { DocumentProjectionDtoSourceTypeEnum } from 'api/document';

export const ownersMeetingInvitationTranslations = {
  deleteModal: {
    title: {
      en: 'Are you sure you want to delete this?',
      de: 'Möchten Sie dies wirklich löschen?',
    },
    content: {
      en: 'You cannot recover your data.',
      de: 'Sie können Ihre Daten nicht wiederherstellen.',
    },
    okText: {
      en: 'Delete',
      de: 'Löschen',
    },
    cancelText: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
  },
  table: {
    headers: {
      etvId: {
        en: 'ETV-ID',
        de: 'ETV-ID',
      },
      propertyId: {
        en: 'Property-ID',
        de: 'Objekt-ID',
      },
      property: {
        en: 'Property',
        de: 'Objekt',
      },
      propertyName: {
        en: 'Property name',
        de: 'Objektbezeichnung',
      },
      propertyManager: {
        en: 'Property manager',
        de: 'Objektbetreuer',
      },
      propertyAccountant: {
        en: 'Accountant',
        de: 'Objektbuchhalter',
      },
      date: {
        en: 'Date',
        de: 'Datum',
      },
      hga: {
        en: 'HGA',
        de: 'HGA',
      },
      economicPlan: {
        en: 'Economic Plan',
        de: 'Wirtschaftsplan',
      },
      hgaStatus: {
        en: 'Status HGA',
        de: 'Status HGA',
      },
      hgaTitle: {
        en: 'HGA Title',
        de: 'HGA Titel',
      },
      economicYear: {
        en: 'Date HGA',
        de: 'Datum HGA',
      },
      wpStatus: {
        en: 'Status Economic Plan',
        de: 'Status Wirtschaftsplan',
      },
      wpTitle: {
        en: 'Economic Plan Title',
        de: 'Wirtschaftsplan Titel',
      },
      status: {
        en: 'Status',
        de: 'Status',
      },
    },
  },
  elements: {
    fileUpload: {
      required: {
        en: ' (Please upload)',
        de: ' (Bitte hochladen)',
      },
      uploadError: {
        message: {
          en: 'Upload failed',
          de: 'Upload fehlgeschlagen',
        },
        description: {
          en: 'Something went wrong during file upload.',
          de: 'Beim Hochladen der Datei ist ein Fehler aufgetreten.',
        },
        wrongDimensions: {
          en: ' PDF dimensions are not matching the A4 format.',
          de: ' Das PDF-Format stimmen nicht mit dem A4-Format überein.',
        },
      },
      downloadError: {
        message: {
          en: 'Download failed',
          de: 'Download fehlgeschlagen',
        },
      },
    },
    createButton: {
      en: 'Create new ETV',
      de: 'Hinzufügen',
    },
    deletionModal: {
      title: {
        en: 'Are you sure you want to delete this?',
        de: 'Möchten Sie dies wirklich löschen?',
      },
      description: {
        en: 'You will not be able to restore your data.',
        de: 'Sie können Ihre Daten nicht wiederherstellen.',
      },
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      delete: {
        en: 'Delete',
        de: 'Löschen',
      },
    },
    dirtModal: {
      title: {
        en: 'You have unsaved changes',
        de: 'Sie haben nicht gespeicherte Änderungen',
      },
      description: {
        en: 'Save the changes before closing section?',
        de: 'Änderungen vor dem Schließen des Abschnitts speichern?',
      },
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      discard: {
        en: 'Discard changes',
        de: 'Änderungen verwerfen',
      },
      save: {
        en: 'Save',
        de: 'Speichern',
      },
    },
    prompt: {
      en: 'You have unsaved changes that will be lost. Do you still want to navigate?',
      de: 'Sie haben nicht gespeicherte Änderungen. Möchten Sie weiter?',
    },
  },
  ownersMeetingStates: {
    DRAFT: {
      en: 'Draft',
      de: 'Entwurf',
    },
    SENT: {
      en: 'Sent',
      de: 'Gesendet',
    },
    DONE: {
      en: 'Done',
      de: 'Finalisiert',
    },
    DECIDED: {
      en: 'Decided',
      de: 'Beschlossen',
    },
    DELETED: {
      en: 'Deleted',
      de: 'Gelöscht',
    },
    EXPIRED: {
      en: 'Expired',
      de: 'Abgelaufen',
    },
    FAILED: {
      en: 'Failed',
      de: 'Gescheitert',
    },
    ACTIVE: {
      en: 'Active',
      de: 'Aktiv',
    },
    CREATED: {
      en: 'Created',
      de: 'Erstellt',
    },
    ERROR: {
      en: 'Failed to send',
      de: 'Konnte nicht gesendet werden',
    },
    SENDING: {
      en: 'Sending',
      de: 'Versenden im Gange',
    },
    FINALIZED: {
      en: 'Finalized',
      de: 'Finalisiert',
    },
    PREPARED: {
      en: 'Prepared',
      de: 'Vorbereitet',
    },
  },
  agendaItemsTable: {
    table: {
      title: {
        en: 'Title',
        de: 'Titel',
      },
      topicNr: {
        en: 'Top-Nr.',
        de: 'Top-Nr.',
      },
      attachments: {
        en: 'Attachments',
        de: 'Anhänge',
      },
    },
    addAgendaItem: {
      en: 'Add Agenda Item',
      de: 'Agendapunkt hinzufügen',
    },
    loadAgendaItemsError: {
      en: 'Failed to load agenda item list.',
      de: 'Liste der Tagesordnungspunkte konnte nicht geladen werden.',
    },
    noItems: {
      en: 'You did not add any agenda items yet. \nYou can add agenda items via button above.',
      de: 'Sie haben noch keine Agendapunkte erstellt.\n Fügen Sie Agendapunkte über den Button oben hinzu.',
    },
  },
  agendaItemDetails: {
    title: {
      en: 'Agenda Item',
      de: 'Tagesordnungspunkt',
    },
    placeholder: {
      en: 'Please select...',
      de: 'Bitte auswählen...',
    },
    typeField: {
      en: 'Type',
      de: 'Art',
    },
    header: {
      saveAndClose: {
        en: 'Save & Close',
        de: 'Speichern & Schließen',
      },
      saveAndOpenNext: {
        en: 'Save & Open next',
        de: 'Speichern & Nächste öffnen',
      },
      saveAndCreateNew: {
        en: 'Save & Create further',
        de: 'Speichern & weiteren anlegen',
      },
      close: {
        en: 'Close',
        de: 'Schließen',
      },
    },
    topDetailsTitle: {
      en: 'TOP Details',
      de: 'Details',
    },
    typeOptions: {
      proposal: {
        en: 'Proposal',
        de: 'Beschlussantrag',
      },
      discussionTopic: {
        en: 'Discussion topic without resolution',
        de: 'Diskussion ohne Beschluss',
      },
    },
    notifications: {
      loadError: {
        message: {
          en: 'Agenda item loading error',
          de: 'Fehler beim Laden des Tagesordnungspunkts',
        },
        description: {
          en: 'Could not load agenda item',
          de: 'Tagesordnungspunkt konnte nicht geladen werden',
        },
      },
      saveSuccess: {
        message: {
          en: 'The agenda item was saved successfully.',
          de: 'Der Tagesordnungspunkt wurde erfolgreich gespeichert.',
        },
      },
      saveError: {
        message: {
          en: 'Agenda item could not be saved.',
          de: 'Tagesordnungspunkt konnte nicht gespeichert werden.',
        },
      },
      deleteSuccess: {
        en: 'Agenda item deleted successfully',
        de: 'Tagesordnungspunkt wurde erfolgreich gelöscht',
      },
      deleteError: {
        en: 'Cannot delete agenda item',
        de: 'Tagesordnungspunkt kann nicht gelöscht werden',
      },
      fieldValidationWarning: {
        en: 'Saved agenda item with missing or invalid fields',
        de: 'Tagesordnungspunkt wurde mit fehlenden oder ungültigen Feldern gespeichert',
      },
    },
  },
  editPage: {
    createTitle: {
      en: 'Create new ETV Invitation',
      de: 'Neue ETV Einladung erstellen',
    },
    editTitle: {
      en: 'Edit ETV Invitation',
      de: 'ETV Einladung bearbeiten',
    },
    viewTitle: {
      en: 'View ETV Invitation',
      de: 'ETV Einladung ansehen',
    },
    saveAll: {
      en: 'Save all',
      de: 'Als Entwurf speichern',
    },
    sendOut: {
      en: 'Preview and send',
      de: 'Vorschau & Versenden',
    },
    checkMessages: {
      en: 'View messages',
      de: 'Nachrichten ansehen',
    },
    close: {
      en: 'Close',
      de: 'Schließen',
    },
    deleteButton: {
      en: 'Delete',
      de: 'Löschen',
    },
    sections: {
      save: {
        en: 'Save and Continue',
        de: 'Speichern und Weiter',
      },
      typeSection: {
        title: {
          en: 'Meeting type',
          de: 'Versammlungsart',
        },
        fields: {
          property: {
            en: 'Property',
            de: 'Objekt',
          },
          type: {
            en: 'ETV type',
            de: 'ETV Art',
          },
          typeOptions: {
            regular: {
              en: 'Ordinary ETV',
              de: 'Ordentliche ETV',
            },
            extraordinary: {
              en: 'Extraordinary ETV',
              de: 'Außerordentliche ETV ',
            },
          },
        },
      },
      documentsSection: {
        title: {
          en: 'Document check and upload',
          de: 'Dokumentenprüfung & Upload',
        },
        subsections: {
          standardDocuments: {
            title: {
              en: 'Standard documents applied to all units',
              de: 'Standard Dokumente für alle Einheiten',
            },
            content: {
              coverLetter: {
                description: {
                  en: 'Cover letter',
                  de: 'Anschreiben',
                },
              },
              powerOfAttorney: {
                description: {
                  en: 'Power of Attorney',
                  de: 'Vollmacht',
                },
              },
              agenda: {
                description: {
                  en: 'Agenda',
                  de: 'Tagesordnung',
                },
              },
              addNew: {
                description: {
                  en: 'Add another document',
                  de: 'Weiteres Dokument hinzufügen',
                },
                buttonLabel: {
                  en: 'Choose pdf file',
                  de: 'PDF Datei auswählen',
                },
              },
            },
          },
          unitSpecificDocuments: {
            title: {
              en: 'Unit specific document',
              de: 'Dokumente für die Einheiten',
            },
            content: {
              documentTypes: {
                en: 'Additional document per unit',
                de: 'Zusätzliches Dokument für jede Einheit',
              },
              houseMoneySettlement: {
                title: {
                  en: 'House money settlement',
                  de: 'Hausgeldabrechnung',
                },
                options: {
                  notIncluded: {
                    en: 'Not included',
                    de: 'Nicht einschließen',
                  },
                  systemGenerated: {
                    en: 'System generated',
                    de: 'System generiert',
                  },
                  powerHausRar: {
                    en: 'Powerhaus RAR file',
                    de: 'Powerhaus RAR Datei',
                  },
                },
                houseMoneySettlementEconomicYear: {
                  en: 'House money settlement',
                  de: 'Hausgeldabrechnung',
                },
              },
              economicPlan: {
                title: {
                  en: 'Economic Plan',
                  de: 'Wirtschaftsplan',
                },
                options: {
                  systemGenerated: {
                    en: 'System generated',
                    de: 'System generiert',
                  },
                  powerHausRar: {
                    en: 'Powerhaus RAR file',
                    de: 'Powerhaus RAR Datei',
                  },
                },
                rarUpload: {
                  description: {
                    en: 'WP: Powerhaus RAR file only',
                    de: 'WP: Powerhaus RAR File ',
                  },
                  buttonLabel: {
                    en: 'Choose file',
                    de: 'Datei auswählen',
                  },
                },
                economicPlanTitle: {
                  en: 'Economic plan',
                  de: 'Wirtschaftsplan',
                },
              },
              wkaTitle: {
                en: 'WKA',
                de: 'WKA',
              },
              addMoreDocumentType: {
                en: 'Add another document type',
                de: 'Weiteres Dokument hinzufügen',
              },
              save: {
                en: 'Save',
                de: 'Speichern',
              },
              delete: {
                en: 'Delete',
                de: 'Löschen',
              },
            },
          },
          unitOverview: {
            title: {
              en: 'Unit Overview and Upload',
              de: 'Einheiten Übersicht und Upload',
            },
            columns: {
              unit: {
                en: 'unit',
                de: 'Einheit',
              },
              owner: {
                en: 'owner',
                de: 'Eigentümer',
              },
              houseMoneySettlement: {
                en: 'house money settlement',
                de: 'hga',
              },
              economicPlan: {
                en: 'economic plan',
                de: 'wp',
              },
              wka: {
                en: 'WKA',
                de: 'WKA',
              },
            },
          },
        },
      },
      appointmentSection: {
        title: {
          en: 'Appointment',
          de: 'Terminvereinbarung',
        },
        fields: {
          location: {
            en: 'Meeting location',
            de: 'Versammlungsort',
          },
          room: {
            en: 'Meeting room',
            de: 'Versammlungsraum',
          },
          startTime: {
            en: 'Start time',
            de: 'Startzeit',
          },
          endTime: {
            en: 'End (planned)',
            de: 'Ende (geplant)',
          },
          date: {
            en: 'Date',
            de: 'Datum',
          },
          signingPerson: {
            en: 'Inviting employee for cover letter',
            de: 'Einladende Person für das Anschreiben',
          },
        },
      },
      agendaSection: {
        title: {
          en: 'Agenda',
          de: 'Tagesordnung',
        },
        fields: {
          addTopic: {
            en: 'Add Agenda item',
            de: 'Tagesordnungspunkt',
          },
          templateSelector: {
            en: 'Select template',
            de: 'Vorlage auswählen',
          },
          title: {
            en: 'Title',
            de: 'Titel',
          },
          description: {
            en: 'Description',
            de: 'Beschreibung',
          },
          resolutionRecordText: {
            en: 'Resolution record text',
            de: 'Beschlussfassungstext',
          },
        },
      },
      shippingSection: {
        title: {
          en: 'Shipping',
          de: 'Versenden',
        },
        noWatermark: {
          en: 'No watermark',
          de: 'Kein Briefpapier anwenden',
        },
        template: {
          title: {
            en: 'Template',
            de: 'Vorlage',
          },
          fields: {
            letter: {
              en: 'Template invitation letter',
              de: 'Vorlage Einladungsanschreiben mit Vollmacht',
            },
            use: {
              en: 'Use selected invitation letter',
              de: 'Verwenden Sie das ausgewählte Einladungsanschreiben',
            },
            subject: {
              en: 'Subject',
              de: 'Betreff',
            },
            body: {
              en: 'Body',
              de: 'Anschreiben',
            },
            watermark: {
              en: 'Watermark',
              de: 'Briefpapier',
            },
          },
        },
        coverLetter: {
          templates: {
            ownersMeetingCoverLetter: {
              en: 'Invitation letter',
              de: 'Einladungsanschreiben',
            },
            conveningOwnersMeetingCoverLetter: {
              en: 'Covening',
              de: 'Einberufungsverlangen',
            },
            oneManOwnersMeetingCoverLetter: {
              en: 'One man meeting',
              de: 'Ein-Mann-Versammlung',
            },
            retryOwnersMeetingCoverLetter: {
              en: 'Retry meeting',
              de: 'Wiederholungsversammlung',
            },
            shortNoticeOwnersMeetingCoverLetter: {
              en: 'With short notice',
              de: 'Mit verkürzter Ladungsfrist ',
            },
          },
        },
      },
    },
  },
  notifications: {
    error: {
      BAD_TYPE_OF_EP: {
        en: 'EPs of type UNIT FIGURES cannot be attached to ETV invitations',
        de: 'Enheiten basierte Wirtschaftspläne können nicht an Einladungen zur Eigentümerversammlung angehängt werden',
      },
      BAD_STATE_OF_EP: {
        en: 'EPs in state DRAFT cannot be attached to ETV invitations',
        de: 'Wirtschaftspläne im Entwurfsstatus können nicht an Einladungen zur Eigentümerversammlung angehängt werden',
      },
    },
    coverLetterloadError: {
      message: {
        en: 'Failed to load invitation letter',
        de: 'ETV Einladung konnte nicht geladen werden',
      },
    },
    loadOwnersMeetingListError: {
      message: {
        en: 'ETV invitations couldn´t be loaded.',
        de: 'ETV Einladungen konnten nicht geladen werden.',
      },
    },
    loadOwnersMeetingError: {
      message: {
        en: 'Failed loading ETV',
        de: 'Fehler beim Laden der ETV',
      },
      description: {
        en: 'Failed loading ETV',
        de: 'Fehler beim Laden der ETV',
      },
    },
    loadUnitsError: {
      en: 'Failed to load units',
      de: 'Fehler beim Laden der Einheiten',
    },
    loadEconomicYearsError: {
      message: {
        en: 'Failed to load economic years',
        de: 'Fehler beim Laden des Wirtschaftsjahrs',
      },
    },
    loadHouseMoneySettlementYearsError: {
      message: {
        en: 'Failed to load house money settlement years',
        de: 'Fehler beim Laden des Hausgeldabrechnungsjahrs',
      },
    },
    loadWkasError: {
      en: 'Failed to load wkas',
      de: 'WKA konnte nicht geladen werden.',
    },
    saveSuccess: {
      message: {
        en: 'ETV saved successfully.',
        de: 'ETV erfolgreich gespeichert.',
      },
    },
    saveError: {
      message: {
        en: 'ETV could not be saved.',
        de: 'ETV konnte nicht gespeichert werden.',
      },
      description: {
        en: 'Cannot save ETV.',
        de: 'ETV kann nicht gespeichert werden.',
      },
    },
    sendOutError: {
      message: {
        en: 'Could not send out ETV.',
        de: 'ETV Einladung konnte nicht gesendet werden.',
      },
      documentGenerationError: {
        en: 'Failed to generate documents for ETV.',
        de: 'Dokumentengenerierung fehlgeschlagen.',
      },
    },
    documentsStillGenerating: {
      title: {
        en: 'Documents are still being generated.',
        de: 'Dokumente werden erstellt.',
      },
      descripiton: {
        en: 'Once the documents have been generated, you can send out the ETV. Please try again after a few minutes.',
        de: 'Sobald die Dokumente erstellt wurden, können Sie die ETV-Einladung versenden. Bitte versuchen Sie es in ein paar Minuten erneut.',
      },
    },
    generateResolutionsRecordsError: {
      message: {
        en: 'Could not generate Resolution Records.',
        de: 'Konnte keine Auflösungssätze erzeugen.',
      },
    },
    generateResolutionsRecordsSuccess: {
      message: {
        en: 'Resolution Records generated successfully.',
        de: 'Auflösung Datensätze erfolgreich generiert.',
      },
    },
    sendSuccess: {
      message: {
        en: 'ETV Invitation was sent out successfully.',
        de: 'ETV Einladung erfolgreich versendet.',
      },
    },
    validationErrors: {
      message: {
        en: 'There are validation errors.',
        de: 'Es sind Validierungsfehler aufgetreten.',
      },
    },
    deleteSuccess: {
      message: {
        en: 'Owners meeting invitation deleted successfully',
        de: 'ETV wurde erfolgreich gelöscht.',
      },
    },
    deleteError: {
      message: {
        en: 'Cannot delete owners meeting invitation.',
        de: 'ETV Einladung kann nicht gelöscht werden.',
      },
    },
  },
  sendWarningModal: {
    title: {
      en: 'Contracts without documents',
      de: 'Verträge ohne Dokumente',
    },
    contentLine1: {
      en: (url: string, name:string) => (
        <p>
          There is no WKA document assigned for the following contracts. If you want the specified contracts to receive WKA documents as well
          <a href={url} target="blank"> assign documents to those contracts</a>
          {' '}
          for
          {' '}
          {name}
          .
        </p>
      ),
      de: (url: string, name:string) => (
        <p>
          Für die folgenden Verträge ist keine Wärmekostenabrechnung zugewiesen. Wenn Sie möchten, dass auch die angegebenen Verträge die Dokumente erhalten,
          <a href={url} target="blank">weisen Sie sie diesen Verträgen zu</a>
          {' '}
          für
          {' '}
          {name}
          .
        </p>
      ),
    },
    contentLine2: {
      en: (documentType: DocumentProjectionDtoSourceTypeEnum, url: string) => (
        <p>
          {`There is no ${documentType === DocumentProjectionDtoSourceTypeEnum.ECONOMIC_PLAN ? 'economic plan' : 'HGA'} for the following contracts.  If you want the specified contracts to also receive documents, `}
          <a href={url} target="blank">{`revert the existing ${documentType === DocumentProjectionDtoSourceTypeEnum.ECONOMIC_PLAN ? 'economic plan' : 'HGA'} back to the draft and recalculate it `}</a>
          or create a new one if it is already activated.
        </p>
      ),
      de: (documentType: DocumentProjectionDtoSourceTypeEnum, url: string) => (
        <p>
          {`Für die folgenden Verträge liegt kein${documentType === DocumentProjectionDtoSourceTypeEnum.ECONOMIC_PLAN ? ' Wirtschaftsplan' : 'e Hausgeldabrechnung'} vor. Wenn Sie möchten, dass auch die angegebenen Verträge die Dokumente erhalten, `}
          <a href={url} target="blank">{`setzen Sie den bestehende${documentType === DocumentProjectionDtoSourceTypeEnum.ECONOMIC_PLAN ? 'n Wirtschaftsplan' : ' Hausgeldabrechnung'} zurück in den Entwurfsstatus und berechnen Sie ihn neu`}</a>
          {' oder erstellen Sie einen neuen, falls er bereits aktiviert ist.'}
        </p>
      ),
    },
    contentLine3: {
      en: 'Do you want to send out anyway? ',
      de: 'Möchten Sie trotzdem versenden? ',
    },
    send: {
      en: 'Send out',
      de: 'Versenden',
    },
    cancel: {
      en: 'Go back',
      de: 'Zurück',
    },
  },

};
