import Icon from '@ant-design/icons';
import { formatDate } from 'lib/Utils';
import _, { isEmpty } from 'lodash';
import { useContext } from 'react';
import { OwnersMeetingProtocolDtoStatusEnum } from '../../../../../api/accounting';
import { ICONS } from '../../../../../components/icons';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import { OwnersMeetingInvitationContext } from '../../../../../contexts/OwnersMeetingInvitationContext';
import { IconFileUpload } from '../../../../../elements/Icons/IconUpload/IconFileUpload';
import { SERIAL_LETTER, useFileUpload } from '../../../../../elements/Inputs/FileUpload/UseFileUpload';
import { ownersMeetingInvitationTranslations } from '../../translations/OwnersMeetingInvitationTranslations';

const getDownloadFileName = (ownersMeeting: any, record: any, documentType: string) => (
  `${
    formatDate(new Date(), 'YYYY-MM-DD')
  }_${
    ownersMeeting.property.propertyIdInternal
  }_${
    documentType
  }_${
    record.unitNrSharingDeclaration
  }_${
    record.ownerName
  }.pdf`);

export const UseOwnersMeetingInvitationUnitDocumentOverviewColumns = (ownersMeeting: any, setDirty: (dirty: boolean) => void) => {
  const { tl } = useContext(LanguageContext);
  const ownersMeetingContext: any = useContext(OwnersMeetingInvitationContext);
  const fileUploader = useFileUpload('', SERIAL_LETTER);
  const isSent = [OwnersMeetingProtocolDtoStatusEnum.SENT, OwnersMeetingProtocolDtoStatusEnum.DONE].includes(ownersMeeting.status);

  const thereAreNoWkaDocuments = ownersMeeting?.units?.filter(unit => unit.wkaDocuments !== undefined).length === 0;

  const columns: any = [
    {
      title: tl(ownersMeetingInvitationTranslations.editPage.sections.documentsSection.subsections.unitOverview.columns.unit),
      dataIndex: 'unitNrSharingDeclaration',
      width: '15%',
    },
    {
      title: tl(ownersMeetingInvitationTranslations.editPage.sections.documentsSection.subsections.unitOverview.columns.owner),
      dataIndex: 'ownerName',
    },
  ];

  if (ownersMeeting.economicPlanId) {
    columns.push({
      title: tl(ownersMeetingInvitationTranslations.editPage.sections.documentsSection.subsections.unitOverview.columns.economicPlan),
      align: 'center',
      dataIndex: 'economicPlanDocument',
      width: '15%',
      render: (text: any, record) => {
        if (text) {
          return (
            <a
              href={text}
              onClick={(e) => {
                e.preventDefault();
                const downloadFileName = getDownloadFileName(ownersMeeting, record, 'WP');
                fileUploader.onDownload(text, downloadFileName);
              }}
            >
              <Icon component={ICONS.file} />
            </a>
          );
        }
        return <Icon component={ICONS.file} />;
      },
    });
  }
  if (ownersMeeting.houseMoneySettlementId) {
    columns.push(
      {
        title: tl(ownersMeetingInvitationTranslations.editPage.sections.documentsSection.subsections.unitOverview.columns.houseMoneySettlement),
        align: 'center',
        dataIndex: 'houseMoneySettlementDocument',
        width: '18%',
        render: (text: any, record) => {
          if (text) {
            return (
              <a
                href={text}
                onClick={(e) => {
                  e.preventDefault();
                  const downloadFileName = getDownloadFileName(ownersMeeting, record, 'HGA');
                  fileUploader.onDownload(text, downloadFileName);
                }}
              >
                <Icon component={ICONS.file} />
              </a>
            );
          }
          return <Icon component={ICONS.file} />;
        },
      },
    );
  }
  if (ownersMeeting.wkaIds) {
    columns.push({
      title: tl(ownersMeetingInvitationTranslations.editPage.sections.documentsSection.subsections.unitOverview.columns.wka),
      align: 'center',
      dataIndex: 'wkaDocuments',
      width: '10%',
      render: (docs: string[], record) => {
        if (!isEmpty(docs)) {
          return (
            <a
              href={docs[0]}
              onClick={(e) => {
                docs.forEach((doc) => {
                  const downloadFileName = getDownloadFileName(ownersMeeting, record, 'WKA');
                  fileUploader.onDownload(doc, downloadFileName);
                });
                e.preventDefault();
              }}
            >
              <Icon component={ICONS.file} />
            </a>
          );
        }
        // return the icon the first time wka is selected
        if (thereAreNoWkaDocuments) {
          return <Icon component={ICONS.file} />;
        }
        return '';
      },
    });
  }

  if (!_.isEmpty(ownersMeeting.documentTypes)) {
    ownersMeeting.documentTypes
      .filter((documentType: any) => documentType && documentType.saved)
      .forEach((documentType: any) => {
        columns.push({
          title: documentType.name,
          align: 'center',
          dataIndex: `${documentType.name}`,
          render: (text: any, row: any) => {
            const additionalDocuments = row.additionalDocuments || [];
            const neededAdditionalDocuments = additionalDocuments.filter((ad: any) => ad.typeName === documentType.name);
            let urlValue = '';
            if (neededAdditionalDocuments.length > 0) {
            // if url exists
              urlValue = neededAdditionalDocuments[0].url;
            }
            return (
              <IconFileUpload
                name={`${row.unitNrSharingDeclaration} ${documentType.name}`}
                value={urlValue}
                onChange={(url) => {
                  if (url !== '') {
                    // save
                    ownersMeetingContext.saveUrlForUnit(url, row.id, documentType.name);
                  } else {
                    // edit
                    ownersMeetingContext.deleteUrlFromUnit(row.id, documentType.name);
                  }
                  setDirty(true);
                }}
                uploadIcon={ICONS.upload}
                downloadIcon={ICONS.download}
                deleteIcon={ICONS.bin}
                accept=".pdf"
                disabled={isSent}
                forLetter
              />
            );
          },
        });
      });
  }
  return columns;
};
