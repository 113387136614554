// domain to polyfill ie's missing fetch
// eslint-disable-next-line import/no-extraneous-dependencies
import 'isomorphic-fetch';

import { PdfProxyHtmlRenderDto } from 'data/pdfProxy';

import * as config from './config';

const buildModuleUrl = (moduleName: string, controllerName: string) => `/services/pmp-${moduleName}/api/v1/${controllerName}`;

export const endpointUrls = {
  USER: buildModuleUrl('user', 'users'),
  DOMAIN: buildModuleUrl('user', 'domains'),

  CONTACT: buildModuleUrl('accounting', 'contacts'),
  PROPERTY: buildModuleUrl('accounting', 'properties'),
  MANAGEMENT_COMPANY: buildModuleUrl('accounting', 'management-companies'),
  SERVICE_COMPANY: buildModuleUrl('accounting', 'service-companies'),
  UNIT: buildModuleUrl('accounting', 'units'),
  WKA: buildModuleUrl('accounting', 'wkas'),
  BUILDING_BANK_ACCOUNT: buildModuleUrl('accounting', 'bank-accounts'),
  UNIT_CONTRACT: buildModuleUrl('accounting', 'unit-contract'),

  INVOICE: buildModuleUrl('accounting', 'invoices'),

  ACCOUNTING: buildModuleUrl('accounting', 'accounting'),
  POSTING: buildModuleUrl('accounting', 'postings'),
  SPECIAL_CONTRIBUTIONS: buildModuleUrl('accounting', 'special-contributions'),
  BANK_CONNECTION: buildModuleUrl('accounting', 'bank-connections'),
  PAYMENT: buildModuleUrl('accounting', 'payments'),
  STANDING_ORDER: buildModuleUrl('accounting', 'standing-orders'),
  DIRECT_DEBITS: buildModuleUrl('accounting', 'direct-debits'),
  SEPA_DIRECT_DEBITS: buildModuleUrl('accounting', 'sepa/direct-debits'),
  SEPA_PAYMENTS: buildModuleUrl('accounting', 'sepa/payments'),
  TRANSACTION: buildModuleUrl('accounting', 'bank-transactions'),
  TRANSACTION_IMPORT: buildModuleUrl('accounting', 'transaction-imports'),

  AGGREGATOR: buildModuleUrl('accounting', 'aggregation'),

  DISTRIBUTION_KEY: buildModuleUrl('accounting', 'distribution'),
  HOUSE_MONEY_SETTLEMENT_AGGREGATOR: buildModuleUrl('accounting', 'house-money-settlement'),
  ECONOMIC_PLAN: buildModuleUrl('accounting', 'economic-plans'),

  OWNERS_MEETING_INVITATION: buildModuleUrl('accounting', 'owners-meeting-invitations'),
  OWNERS_MEETING_PROTOCOL: buildModuleUrl('accounting', 'owners-meeting-protocol'),
  RESOLUTION_RECORDS: buildModuleUrl('accounting', 'resolution-records'),
  SERIAL_LETTER: buildModuleUrl('accounting', 'serial-letters'),

  DOCUMENT: buildModuleUrl('document', 'documents'),
  TEMPLATES: buildModuleUrl('document', 'templates'),
  WATERMARKS: buildModuleUrl('document', 'watermarks'),

  PDF_PROXY: '/services/pdf-proxy',

  FIN_API: 'services/finapi',

  STATIC_CSS: '/static/css',
};

const handleRespCode = (resp: any) => {
  if (resp.status >= 200 && resp.status < 300) {
    if (resp.status === 204) {
      return resp;
    }
    return resp.text().then((text: string) => (text ? JSON.parse(text) : {}));
  }
  if (resp.status < 500) {
    return resp.json().then((jsonData: any) => {
      throw jsonData;
    });
  }
  throw resp;
};

const handleFileToArrayBuffer = (resp: any): Promise<Response> => {
  if (resp.status >= 200 && resp.status < 300) {
    // Workaround-ish handler for pdfs.
    // Needs to be converted to blob, back to response then to arraybuffer.
    // ArrayBuffers can be converted to TypedArrays
    return resp.blob()
      .then((blob: Blob) => new Response(blob).arrayBuffer());
  }
  throw resp;
};
// Commented so that the linter doesn't complain. Uncomment if used.
// const handleRespCodeForBlob = function (p) {
//   return p.then((resp) => {
//     if (resp.status >= 200 && resp.status < 300) {
//       if (resp.status === 204)
//         return resp;
//       return resp.blob();
//     }
//     throw resp;
//   });
// };
const handleError = (e: any) => {
  throw e;
};

const buildFetchOptions = () => {
  const token: string = getTokenFromLocalStorage();
  const fetchOptions: any = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json; charset=utf-8',
      Authorization: token ? `Bearer ${token}` : null,
    },
    credentials: 'include', // include, same-origin, *omit
    mode: 'cors', // no-cors, *same-origin
    redirect: 'follow', // *manual, error
    referrer: 'no-referrer', // *client
  };

  return {
    withHeader(name: string, value: any) {
      fetchOptions.headers[name] = value;
      return this;
    },
    body(body: any) {
      fetchOptions.body = body;
      return this;
    },
    method(method: string) {
      if (['POST', 'GET'].indexOf(method) !== -1) {
        fetchOptions.method = method;
      } else {
        fetchOptions.method = 'POST';
        fetchOptions.headers['X-HTTP-Method-Override'] = method;
      }
      return this;
    },
    build() {
      return fetchOptions;
    },
  };
};

const DUMMY_PROMISE = {
  then: () => DUMMY_PROMISE, catch: () => DUMMY_PROMISE, finally: () => DUMMY_PROMISE,
};

function getTokenFromLocalStorage() {
  const authString: any = localStorage.getItem('auth');
  const auth: any = JSON.parse(authString);
  const token: string = auth ? auth.token : null;
  return token;
}

function transformToRequestParams(additionalData: any) {
  let params = '';
  if (typeof additionalData === 'object') {
    params = Object.keys(additionalData)
      .reduce((s, k) => (additionalData[k] !== null && typeof additionalData[k] !== 'undefined'
        ? `${s}&${k}=${encodeURIComponent(additionalData[k])}` : `${s}`), '')
      // strip away the first &
      .substr(1);
  } else if (additionalData) {
    params = additionalData;
  }
  return params;
}

const getFileByPath = (baseUrl: string, path: string, data?: any) => {
  const params = data ? transformToRequestParams(data) : '';
  let p = fetch(`${baseUrl}${path}${params === '' ? '' : `?${params}`}`,
    buildFetchOptions()
      .method('GET')
      .build());
  p = p.then(handleFileToArrayBuffer);
  p = p.catch(handleError);
  return p;
};

export const frontend = {
  getFileByPath(path: string, data?: any) {
    return getFileByPath('', path, data);
  },
};

const backend = {
  query(type: string, query: any, forceOnServer: boolean = false) {
    // const itsSettingsObj = Object.keys(query).every(k => ['q', 'p', 'l', 's'].indexOf(k) !== -1);
    // const querySettings = itsSettingsObj ? query : {q: query};
    const querySettings = query;
    return this.get(`/crud/${type}/`, querySettings, forceOnServer);
  },
  get(path: string, data: any, forceOnServer: boolean = false) {
    const g: any = global;
    if (g.isServer && !forceOnServer) {
      return DUMMY_PROMISE;
    }
    const params = transformToRequestParams(data);
    let p = fetch(`${config.backendUrl}${path}${params === '' ? '' : `?${params}`}`,
      buildFetchOptions()
        .method('GET')
        .build());
    p = p.then(handleRespCode);
    p = p.catch(handleError);
    return p;
  },
  getWithMultipleSort(path: string, data: { sort: { field: string, order: string }[], [key: string]: any }, forceOnServer: boolean = false) {
    const g: any = global;
    if (g.isServer && !forceOnServer) {
      return DUMMY_PROMISE;
    }

    const { sort, ...rest } = data;
    const sortQueryString = sort.map(({ field, order }) => `sort=${field},${order}`).join('&');
    const otherParams = transformToRequestParams(rest);
    const queryString = `?${[sortQueryString, otherParams].join('&')}`;

    let p = fetch(`${config.backendUrl}${path}${queryString}`,
      buildFetchOptions()
        .method('GET')
        .build());
    p = p.then(handleRespCode);
    p = p.catch(handleError);
    return p;
  },
  getFile(path: string) {
    return getFileByPath('', path);
  },
  /**
   * Open the download link in a new tab with the authorization token in the URI
   *
   * The server responds with the correct file
   * 1. name
   * 2. mime-type
   * 3. auto-download header
   *
   * The tab will be autoclosed after the user has decided to download the document (or not)
   *
   * Note: Please ensure that the original trigger of the call is a user action otherwise some browsers may block the download.
   * @param documentId the id of the document to download
   */
  downloadDocumentUsingNewTab(documentId:number) {
    const authorizationQueryParameterName = 'authorization';
    const token = getTokenFromLocalStorage();
    window.open(`${config.backendUrl}/v2/documents/${documentId}/download?${authorizationQueryParameterName}=${token}`, 'blank');
  },
  getFileByPath(path: string, data?: any) {
    return getFileByPath(config.backendUrl, path, data);
  },
  getFileUsingPost(path: string, data: any) {
    let p = fetch(`${config.backendUrl}${path}`,
      buildFetchOptions()
        .method('POST')
        .body(JSON.stringify(data))
        .build());
    p = p.then(handleFileToArrayBuffer);
    p = p.catch(handleError);
    return p;
  },
  getFileUsingPostToProxy(path: string, data: PdfProxyHtmlRenderDto) {
    let p = fetch(`${config.pdfProxyUrl}${path}`,
      {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
        method: 'POST',
        mode: 'cors',
      });
    p = p.then(handleFileToArrayBuffer);
    p = p.catch(handleError);
    return p;
  },
  post(path: string, data: any) {
    let p = fetch(`${config.backendUrl}${path}`,
      buildFetchOptions()
        .method('POST')
        .body(JSON.stringify(data))
        .build());
    p = p.then(handleRespCode);
    p = p.catch(handleError);
    return p;
  },
  postFiles(path: string, files: Blob[], fileNames: string[], additionalData: any) {
    const h: any = {};
    const data: FormData = new FormData();
    for (let i = 0; i < files.length; i += 1) {
      data.append('files', files[i], fileNames[i]);
    }
    h.Accept = 'application/json';
    const authString: any = localStorage.getItem('auth');
    const auth: any = JSON.parse(authString);
    const token: string = auth ? auth.token : null;
    h.Authorization = `Bearer ${token}`;
    const params = transformToRequestParams(additionalData);
    let p = fetch(`${config.backendUrl}${path}${params === '' ? '' : `?${params}`}`, {
      method: 'POST',
      headers: h,
      body: data,
    });
    p = p.then(handleRespCode);
    p = p.catch(handleError);
    return p;
  },
  postFileWithName(path: string, file: Blob, fileName: string, additionalData: any) {
    const data: FormData = new FormData();
    data.append('file', file, fileName);
    return this.postFileData(path, data, additionalData);
  },
  postFile(path: string, file: File, additionalData: any) {
    const data: FormData = new FormData();
    data.append('file', file);
    return this.postFileData(path, data, additionalData);
  },
  postFileData(path: string, data: any, additionalData: any) {
    const h: any = {};
    h.Accept = 'application/json';
    const authString: any = localStorage.getItem('auth');
    const auth: any = JSON.parse(authString);
    const token: string = auth ? auth.token : null;
    h.Authorization = `Bearer ${token}`;
    const params = transformToRequestParams(additionalData);
    let p = fetch(`${config.backendUrl}${path}${params === '' ? '' : `?${params}`}`, {
      method: 'POST',
      headers: h,
      body: data,
    });
    p = p.then(handleRespCode);
    p = p.catch(handleError);
    return p;
  },
  // postFileAndData(path: string, file: Blob, additionalData: any) {
  //   const data: FormData = new FormData();
  //   data.append('file', file);
  //   data.append('data', JSON.stringify(additionalData));

  //   const h: any = {};
  //   h.Accept = 'application/json';
  //   const authString: any = localStorage.getItem('auth');
  //   const auth: any = JSON.parse(authString);
  //   const token: string = auth ? auth.token : null;
  //   h.Authorization = `Bearer ${token}`;
  //   let p = fetch(`${config.backendUrl}${path}`, {
  //     method: 'POST',
  //     headers: h,
  //     body: data,
  //   });
  //   p = p.then(handleRespCode);
  //   p = p.catch(handleError);
  //   return p;
  // },
  put(path: string, data: any) {
    let p = fetch(`${config.backendUrl}${path}`,
      buildFetchOptions()
        .method('PUT')
        .body(typeof data === 'string' ? data : JSON.stringify(data))
        .build());
    p = p.then(handleRespCode);
    p = p.catch(handleError);
    return p;
  },
  patch(path: string, data: any) {
    let p = fetch(`${config.backendUrl}${path}`,
      buildFetchOptions()
        .method('PATCH')
        .body(JSON.stringify(data))
        .build());
    p = p.then(handleRespCode);
    p = p.catch(handleError);
    return p;
  },
  delete(path: string, data: any) {
    let p = fetch(`${config.backendUrl}${path}`,
      buildFetchOptions()
        .method('DELETE')
        .body(JSON.stringify(data))
        .build());
    p = p.then(handleRespCode);
    p = p.catch(handleError);
    return p;
  },
};

export default backend;
