import { ReportDocumentCover } from 'storybook-components/documents/ReportDocumentCover/ReportDocumentCover';
import { useDunningAnnexDocumentCover as hook } from './useDunningAnnexDocumentCover';

type ReturnTypeOfHook = ReturnType<typeof hook>;
type ReturnTypeOfReportDocumentCover = ReturnType<ReturnTypeOfHook['getDunningAnnexDocumentCoverProps']>

interface DunningAnnexDocumentCoverProps {
  useReportDocumentCover: ReturnTypeOfReportDocumentCover
}


export const DunningAnnexDocumentCover = ({
  useReportDocumentCover,
}: DunningAnnexDocumentCoverProps) => {
  const {
    title,
    reportInfo,
  } = useReportDocumentCover;
  return (
    <ReportDocumentCover
      title={title}
      reportInfo={reportInfo}
    />
  );
};
