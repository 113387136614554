import Button from 'elements/Buttons/Button/Button';
import SelectInputWithLink from 'storybook-components/inputs/select/SelectorWithLink/SelectorWithLink';
import TextInput from './TextInput/TextInput';
import NumberInput from './NumberInput/NumberInput';
import FileUpload from './FileUpload/FileUpload';
import NumberInputWithButtons from './NumberInputWithButtons/NumberInputWithButtons';
import SelectInput from './SelectInput/SelectInput';
import DateInput from './DateInput/DateInput';
import CheckboxInput from './CheckboxInput/CheckboxInput';
import RadioGroupInput from './RadioGroupInput/RadioGroupInput';
import { CurrencyInput } from './CurrencyInput/CurrencyInput';
import { PercentageInput } from './PercentageInput/PercentageInput';
import SmartSearch from './SmartSearch/SmartSearch';
import RangeInput from './RangeInput/RangeInput';
import CheckboxGroupInput from './CheckboxGroupInput/CheckboxGroupInput';
import FileUploadLine from './FileUploadLine/FileUploadLine';
import RadioGroupWithBox from './RadioGroupWithInputOption/RadioGroupWithBox';
import TextAreaInput from './TextAreaInput/TextAreaInput';
import YearInput from './YearInput/YearInput';
import SubsectionTitle from './SubsectionTitle/SubsectionTitle';
import EconomicYearPicker from './EconomicYearPicker/EconomicYearPicker';
import ValidationError from './ValidationError/ValidationError';
import TimeInput from './TimeInput/TimeInput';
import DownloadButton from '../Buttons/DownloadButton/DownloadButton';
import RichTextEditor from './RichTextEditor/RichTextEditor';
import DropdownTextInput from './DropdownTextInput/DropdownTextInput';
import { AccountFilterComponent } from './AccountFilterComponent/AccountFilterComponent';
import CurrencyInputWithPrefix from './CurrencyInputWithPrefix/CurrencyInputWithPrefix';

const InputTypes: any = {
  text: TextInput,
  number: NumberInput,
  numberWithButtons: NumberInputWithButtons,
  file: FileUpload,
  fileLine: FileUploadLine,
  select: SelectInput,
  date: DateInput,
  range: RangeInput,
  checkbox: CheckboxInput,
  checkboxGroup: CheckboxGroupInput,
  radioGroup: RadioGroupInput,
  currency: CurrencyInput,
  currencyWithPrefix: CurrencyInputWithPrefix,
  percentage: PercentageInput,
  smartSearch: SmartSearch,
  radioGroupWithBox: RadioGroupWithBox,
  textArea: TextAreaInput,
  year: YearInput,
  label: SubsectionTitle,
  economicYearPicker: EconomicYearPicker,
  validationError: ValidationError,
  button: Button,
  time: TimeInput,
  downloadButton: DownloadButton,
  richTextEditor: RichTextEditor,
  dropdownTextInput: DropdownTextInput,
  accountFilter: AccountFilterComponent,
  selectWithLink: SelectInputWithLink,
};

export default InputTypes;
