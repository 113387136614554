import { WatermarkConfigDto } from 'api/document/models/WatermarkConfigDto';

export function getStandardWatermarkConfig(): WatermarkConfigDto {
  return {
    watermarkPageConfigDto: [
      { watermarkPageNumber: 1, pageRange: '1,1' },
      { watermarkPageNumber: 2, pageRange: '2,n' },
    ],
  };
}

export function getSecondPageWatermarkConfig(): WatermarkConfigDto {
  return {
    watermarkPageConfigDto: [
      { watermarkPageNumber: 2, pageRange: '1,n' },
    ],
  };
}
