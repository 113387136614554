
import ReactDOMServer from 'react-dom/server';
import { Footer, FooterProps } from 'storybook-components/documents/Footer/Footer';

export const getFooter = (props: FooterProps): string => ReactDOMServer.renderToStaticMarkup(
  <Footer
    unitNr={props.unitNr}
    propertyName={props.propertyName}
    documentTitle={props.documentTitle}
  />,
);
